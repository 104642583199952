import {useState} from 'react';
import { createPortal } from "react-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 200,
        color: '#fff',
    },
}));

const LoadingSpinner = (props) => {
    const classes = useStyles();

    return createPortal((
        <Backdrop className={classes.backdrop} open={props.open}>
            <CircularProgress color={props.color} />
        </Backdrop>
    ), document.getElementById('portal'))
}

export default LoadingSpinner;