import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { AuthContextProvider } from './store/auth-context';
import PrivateRoute from "./utils/protectedRoute";
//import LoginPage from "./views/Auth/LoginPage";

// core components
//import AuthLayout from "layouts/Auth.js";
//import RtlLayout from "layouts/RTL.js";
//import AdminLayout from "layouts/Admin.js";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner.js";

const AuthLayout = React.lazy(() => import("layouts/Auth.js"));
const RtlLayout = React.lazy(() => import("layouts/RTL.js"));
const AdminLayout = React.lazy(() => import("layouts/Admin.js"));
const LoginPage = React.lazy(() => import("views/Auth/LoginPage"));

const theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiFormLabel: {
            root: {
                fontWeight: 800,
                color: '#777 !important',
                fontSize: 14
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                '&[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
                    padding: '8px !important'
                }

            }
        }
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <BrowserRouter>
                    <Suspense fallback={<LoadingSpinner color="primary" open={true} />} >
                        <Switch>
                            <PrivateRoute path="/admin/user" component={AdminLayout} />
                            <PrivateRoute path="/admin" component={AdminLayout} />
                            <PrivateRoute path="/rtl" component={RtlLayout} />

                            <Route path="/login" component={LoginPage} />
                            <Route path="/auth" component={AuthLayout} />

                            <Redirect from="/" to="/admin/dashboard" />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </AuthContextProvider>
        </ThemeProvider>
    );
}

export default App;