import {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from 'store/auth-context';

function ProtectedRoute({ children, component: Component, ...rest }) {
    const authCtxt = useContext(AuthContext);
    //const user = useSelector(state => state.auth.user);
    const isLoggedIn = authCtxt.isLoggedIn;

    return isLoggedIn
        ? (<Route {...rest} component={Component} />)
        : (<Redirect to={'/auth/login'} />)
}

export default ProtectedRoute