import { createContext, useEffect, useState, useCallback } from "react";

const AuthContext = createContext({
	token: '',
	isLoggedIn: false,
	user: null,
	login: (token) => { },
	logout: () => { },
	updateUser: (userObj) => { },
});

let authTimer;

function getTimeToExpireToken(expiresAt) {
	//Esure that we are dealing with an int, and not string
	expiresAt = parseInt(expiresAt);
	const currentTime = new Date().getTime();
	const expirationTime = new Date(expiresAt).getTime();
	return expirationTime - currentTime;
}

function getDataFromStorage() {
	const storedExpirationTime = localStorage.getItem('crmExpirationTime');
	const remainingTime = getTimeToExpireToken(storedExpirationTime);
	/*
	 * If expiration time is less than 1 min (60k ms), 
	 * then, consider it as good as logged-out
	 */
	if (!remainingTime || remainingTime < 60000) {
		localStorage.removeItem('crmToken');
		localStorage.removeItem('crmExpirationTime');
		localStorage.removeItem('crmUser');
		return null;
	}

	//If we reached till her, this means we are good to go.
	const storedToken = localStorage.getItem('crmToken');
	const storedUser = localStorage.getItem('crmUser');

	return {
		token: storedToken,
		remainingTime: remainingTime,
		user: storedUser,
	}
}
export const AuthContextProvider = (props) => {
	const tokenData = getDataFromStorage();
	let initialToken, currentUser;

	if (tokenData) {
		initialToken = tokenData.token;
		currentUser = JSON.parse(tokenData.user);
	}
	const [token, setToken] = useState(initialToken);
	const [user, setUser] = useState(currentUser);

	const isUserLoggedIn = !!token;

	useEffect(() => {
		if (tokenData) {
			authTimer = setTimeout(logoutHandler, tokenData.remainingTime);

			/*
			 * TODO: Fetch the user info from server, because 
			* user meta like NamedNodeMap, Picture, and Phone might
			* have changed since our last login. Avoid stale data
			*/
		}
	}, [tokenData, logoutHandler])

	const logoutHandler = useCallback(() => {
		localStorage.removeItem('crmToken');
		localStorage.removeItem('crmExpirationTime');
		localStorage.removeItem('crmUser');
		setToken('');

		if (authTimer) {
			clearTimeout(authTimer);
		}
	}, [])

	const loginHandler = (tkn, expiresAt, user) => {
		/*
		 * PHP returns timestamp only in seconds, while 
		 * JS timestamps are in milliseconds, so best to convert PHP 
		 * timestamps to milliseconds as well.
		 */
		expiresAt = parseInt(expiresAt) * 1000;
		localStorage.setItem('crmToken', tkn);
		localStorage.setItem('crmExpirationTime', expiresAt);
		localStorage.setItem('crmUser', JSON.stringify(user));
		setToken(tkn);
		setUser(user);

		const remainingTime = getTimeToExpireToken(expiresAt);
		authTimer = setTimeout(logoutHandler, remainingTime);
	}

	const updateUserHandler = (updatedUserObj) => {
		localStorage.setItem('crmUser', JSON.stringify(updatedUserObj));
		setUser(updatedUserObj);		
	}

	const contextValue = {
		token: token,
		isLoggedIn: isUserLoggedIn,
		user: user,
		login: loginHandler,
		logout: logoutHandler,
		updateUser: updateUserHandler,
	}

	return (
		<AuthContext.Provider value={contextValue}>
			{props.children}
		</AuthContext.Provider>
	)
}

export default AuthContext;
