/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import './wdyr'; // <--- first import

import React from "react";
import ReactDOM from "react-dom";

import App from './App';    

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

ReactDOM.render(<App />, document.getElementById('root'));
